import axios from 'axios'

import VueToast from 'vue-toast-notification';
import Vue from 'vue';
import {GetToken,RemoveToken} from '@/utils/auth';
Vue.use(VueToast);

import {BASE_API} from './base'


// create an axios instance
const service = axios.create({
  // baseURL: 'http://localhost:8086/api/v1/', // url = base url + request url
  baseURL: BASE_API,
  //baseURL: 'https://signboard-mn.herokuapp.com/api/v1/', // url = base url + request url
  withCredentials: false, // send cookies when cross-domain requests
  timeout: 50000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent


      // console.log(config.data);
      // let dataStr = JSON.stringify(config.data);
      // let str1 = btoa(dataStr);
      // console.log(str1);
      // let str2 = Math.random().toString(36).substring(4)+str1.substr(3)+str1.substring(0,3);

      // let data = btoa(str2);
      // config.data = {
      //   xyzt:data
      // }




      config.headers['Authorization'] = "Bearer " + GetToken()
    //}
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(

  response => {
    const res = response.data


    // if the custom code is not 20000, it is judged as an error.
    if (!(res.code === 0 || res.success)) {

     Vue.$toast.open({
        message: res.message || 'error',
        type: 'error',
        position:'top-right',
        duration:3000
        // all other options
    });





      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 1004) {
        RemoveToken();
        location.reload();
        // to re-login

      }
      return Promise.reject(res.message || 'error')
    } else {
      return res
    }
  },
  error => {
    console.log(error.response) // for debug
    let message=error.message;
    if(error.response&&error.response.data&&error.response.data.message){
      message+=(' - '+error.response.data.message);
    }
    Vue.$toast.open({
      message: message,
      type: 'error',
      position:'top-right',
      duration:3000
      // all other options
  });
    return Promise.reject(error)
  }
)

export default service
