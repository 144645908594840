import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/timekeeping/me",
      component: () => import("@/views/theme/Base"),
      children: [{
        path: "/admin/users",
        name: "admin-users",
        component: () => import("@/views/pages/admin/user/AllUser.vue")
        
      },
      {
        path: "/admin/users/:id",
        name: "admin-user-detail",
        component: () => import("@/views/pages/admin/user/UserDetail.vue")
        
      },
      {
        path: "/admin/stores",
        name: "admin-stores",
        component: () => import("@/views/pages/admin/store/AllStore.vue")
        
      },{
        path: "/admin/stores/new",
        name: "admin-stores-new",
        component: () => import("@/views/pages/admin/store/StoreDetail.vue")
        
      },
      {
        path: "/admin/stores/:id",
        name: "stores-detail",
        component: () => import("@/views/pages/admin/store/StoreDetail.vue")
        
      },
      {
        path: "/timekeeping/me",
        name: "timekeeping-me",
        component: () => import("@/views/pages/timekeeping/user/UserShifts.vue")
      },
      {
        path: "/timekeeping/employee",
        name: "timekeeping-employee",
        component: () => import("@/views/pages/timekeeping/admin/EmployeeShifts.vue")
      },
      {
        path: "/workshifts/:id",
        name: "workshift-info",
        component: () => import("@/views/pages/timekeeping/user/ShiftDetail.vue")
        
      },
      {
        path: "/salary/setup",
        name: "salary-setup",
        component: () => import("@/views/pages/salary/setup/SetupTable.vue")
        
      },
      {
        path: "/salary/salary-sheets",
        name: "salary-sheets",
        component: () => import("@/views/pages/salary/sheet/SalarySheetLst.vue")
      },
      {
        path: "/salary/salary-sheets/:id",
        name: "salary-sheet-detail",
        component: () => import("@/views/pages/salary/sheet/SalarySheetDetail.vue")
      },
      {
        path: "/salary/my-salary-sheets",
        name: "my-salary-sheets",
        component: () => import("@/views/pages/salary/sheet/MySalarySheetLst.vue")
      },
      {
        path: "/users/me",
        name: "my-info",
        component: () => import("@/views/pages/admin/user/UserDetail.vue")
      }
      
      
    ]
    },
    {
      path:'/login',
      component: () => import("@/views/pages/auth/Login2.vue"),
      name:'lg',
      children: [
        
        {
          path: "/login",
          name: "login",
          component: () => import("@/views/pages/auth/components/Login.vue")
        },
        {
          path:'/signup',
          component: () => import("@/views/pages/auth/components/Signup.vue"),
          name:'signup'
        },
      ]
    },
    {
      path:'/logo',
      component: () => import("@/views/pages/utils/CombineLogo.vue"),
      name:'logo'
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/views/pages/error/Error-1.vue")
    },
    {
      path:'/*',
      redirect:'/404'
    }
    
  ]
});
