import request from '@/utils/request'
export function login(data) {
  return request({
    url: 'users/login',
    method: 'post',
    data: data
  })
}
export function logout(data) {
  return request({
    url: 'users/logout',
    method: 'post',
    data: data
  })
}
export function resetPassword(data) {
  return request({
    url: 'users/reset-password',
    method: 'post',
    data: data
  })
}
export function getAllUser(data) {
  return request({
    url: 'users/all',
    method: 'post',
    data: data
  })
}
export function createNewUser(data) {
  return request({
    url: 'users/new-user',
    method: 'post',
    data: data
  })
}
export function getUserInfo(data) {
  return request({
    url: 'users/get-info',
    method: 'post',
    data: data
  })
}
export function updateUserInfo(data) {
  return request({
    url: 'users/update-info',
    method: 'post',
    data: data
  })
}
export function changePassword(data) {
  return request({
    url: 'users/change-password',
    method: 'post',
    data: data
  })
}
