const UserRoleMap={
    9:{
        text:'Admin',
        color:'teal'
    },
    5:{
        text:'QL Cửa hàng',
        color:'light-blue'
    },
    1:{
        text:'Nhân viên',
        color:'deep-purple darken-1'
    }
}
const UserStatusMap={
    1:{
        text:'Đang làm việc',
        color:'green'
    },
    0:{
        text:'Nghỉ',
        color:'red'
    }
}
const TimeShift=[
    {
        name:"SA",
        begin:0,
        end:12
    },
    {
        name:"CH",
        begin:12,
        end:18
    },
    {
        name:"TO",
        begin:18,
        end:25
    }
]
const SalaryTypeMap={
    1:{
        text:'Giờ',
        color:'#673AB7'
    },
    // 2:{
    //     text:'Ngày',
    //     color:'#3F51B5'
    // },
    3:{
        text:'Tháng',
        color:'#2196F3'
    }
}

const SalarySheetStatusMap={
    0:{
        text:'Hủy',
        color:'red'
    },
    1:{
        text:'Mới tạo',
        color:'#006064'
    },
    2:{
        text:'Lưu nháp',
        color:'orange'
    },
    3:{
        text:'Chốt sổ',
        color:'green'
    }
    
}
module.exports={
    UserRoleMap:UserRoleMap,
    UserStatusMap:UserStatusMap,
    TimeShift:TimeShift,
    SalaryTypeMap:SalaryTypeMap,
    SalarySheetStatusMap:SalarySheetStatusMap
}