import {UserRoleMap,
    UserStatusMap,
    TimeShift,
    SalaryTypeMap,SalarySheetStatusMap
} from './mapping'
import Vue from "vue";

Vue.filter('roleStatusText', function(role) {
   if(UserRoleMap[role]){
       return UserRoleMap[role].text;
   }
   return '---'
})
Vue.filter('roleStatusColor', function(role) {
    if(UserRoleMap[role]){
        return UserRoleMap[role].color;
    }
    return 'grey'
})

Vue.filter('userStatusText', function(status) {
    if(UserStatusMap[status]){
        return UserStatusMap[status].text;
    }
    return '---'
 })
 Vue.filter('userStatusColor', function(status) {
     if(UserStatusMap[status]){
         return UserStatusMap[status].color;
     }
     return 'grey'
 })

 Vue.filter('salaryTypeText', function(status) {
    if(SalaryTypeMap[status]){
        return SalaryTypeMap[status].text;
    }
    return '---'
 })
 Vue.filter('salaryTypeColor', function(status) {
     if(SalaryTypeMap[status]){
         return SalaryTypeMap[status].color;
     }
     return 'grey'
 })
 Vue.filter('salarySheetStatusText', function(status) {
    if(SalarySheetStatusMap[status]){
        return SalarySheetStatusMap[status].text;
    }
    return '---'
 })
 Vue.filter('salarySheetStatusColor', function(status) {
     if(SalarySheetStatusMap[status]){
         return SalarySheetStatusMap[status].color;
     }
     return 'grey'
 })

 Vue.filter('timeShiftName', function(time) {
    if(time){
        time = Date.parse(time);
        var hours = time.getHours();
        for (let index = 0; index < TimeShift.length; index++) {
            const e = TimeShift[index];
            if(hours>=e.begin&&hours<e.end){
                return time.toString('dd/MM/yy') +'-'+ e.name;
            }
            
        }
        
    }
    return '----'
})
Vue.filter('toDMYHM', function(time) {
    if(time){
        
        return Date.parse(time).toString('dd/MM/yy HH:mm')
    }
    return '----'
})
Vue.filter('toDMY', function(time) {
    if(time){
        
        return Date.parse(time).toString('dd/MM/yyyy')
    }
    return '----'
})
Vue.filter('toHM', function(time) {
    if(time){
        
        return Date.parse(time).toString('HH:mm')
    }
    return '----'
})
 Vue.filter('numberF', function(val) {
    if(val!==null){
        val = Number(val);
        return val.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }
    return '--'
})
Vue.filter('numberFR', function(val) {
    if(val!==null){
        val = Number(val);
        return val;
    }
    return '--'
})
Vue.filter('numberF0', function(val) {
    if(val!==null){
        val = Number(val);
        var re = '\\d(?=(\\d{' + ( 3) + '})+' + ( '$') + ')';
     return val.toFixed(Math.max(0, ~~0)).replace(new RegExp(re, 'g'), '$&,');
        
    }
    return '--'
})