// USA
export const locale = {
    TRANSLATOR: {
      SELECT: "Chọn ngôn ngữ"
    },
    MENU: {
      NEW: "new",
      ACTIONS: "Actions",
      CREATE_POST: "Create New Post",
      PAGES: "Pages",
      FEATURES: "Features",
      APPS: "Apps",
      DASHBOARD: "Trang chủ",
      PARTY_A:"QL Bên thuê",
      PARTY_B:"QL Đối tác bên thuê"
    },
  };
  